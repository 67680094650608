<template>
  <div>
    <h1>Warnings</h1>
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Warnings',
}
</script>

<style lang="scss" scoped>
  // 
</style>
